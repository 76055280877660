#paceSpan {
    text-align: center;
}

@media (min-width: 800px) {
    input#ridewithgps {
        width: 8em;
        height: 3em;
        padding: 12px;
    }
}

.highlighted {
    background-color: rgba(167, 207, 255, 0.4);
}

form.form-inline {
    margin-bottom: 0;
}

img#stravaImage {
    width: 5.2rem;
    height: 2.6rem;
}

img#bugImage {
    width: 2rem;
    height: 2rem;
}

.timeHeaderCell {
    font-size: 80%;
    width: 54px;
    display: inline-block;
}

.headerCell {
    font-size: 80%;
    /* width: 60% */
}

.symbolHeaderCell {
    font-size: 120%;
    width: 28px;
    display: block;
}

.clickableHeaderCell {
    font-size: 80%;
    /* width: 60%; */
    color: #0000EE
}

.largerClickableHeaderCell {
    font-size: 100%;
    width: 90%;
    color: #0000EE
}

.largerStruckClickableHeaderCell {
    font-size: 100%;
    width: 90%;
    color: #0000EE;
    text-decoration: line-through;
}

.chanceRainCell {
    width: 80px
}
@keyframes glowing {
    0% {
        box-shadow: 0 0 5px #0000EE;
    }

    50% {
        box-shadow: 0 0 15px #0000EE;
    }

    100% {
        box-shadow: 0 0 20px #0000EE40;
    }
}

.glowing_input {
    animation: glowing 1300ms infinite;
}

input#finishTime.form-control {
    display: inline-flex;
    width: 17em;
    margin-top: 3px;
    margin-bottom: 0px;
    height: 28px;
    flex: 1.75;
    padding-top: 5px;
    margin-right: 5px;
    background-color: #e9ecef;
}

input#finishTime.finish-time {
    display: inline-flex;
    width: 16em;
    margin-top: 3px;
    margin-bottom: 0px;
    flex: 0;
    font-size: .875rem;
    background-color: #e9ecef;
}

/*
input#finishTime.finish-time {
    display: inline-flex;
    width: 15em;
    margin-top: 3px;
    margin-bottom: 0px;
    height: 28px;
    flex: 1;
    padding-top: 5px;
    !*margin-right: 5px;*!
    font-size: .875rem;
    background-color: #e9ecef;
}
*/
input#interval.form-control {
    padding-top: 5px;
    display: inline-flex;
    align-self: center;
}

input.form-group-sm {
    padding-top: 5px;
    display: inline-flex;
}

input.form-inline {
    padding-top: 5px;
    display: inline-flex;
    align-self: center;
}

span.pt-icon-standard.pt-icon-calendar {
    padding-top: 5px;
    display: inline-flex;
    align-self: center;
}

select.form-control {
    padding-top: 5px;
    display: inline-flex;
}

select#pace.form-control {
    padding-top: 5px;
    display: inline-flex;
    align-self: flex-end;
}

#gustyWeather {
    color: #CC0000;
}

table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped.bp4-interactive td,
table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped.bp4-interactive th {
    padding: 0.25rem; 
}

.spacer {
    display: flex;
    margin-bottom: 1rem;
}

.bicycle_image {
    background-image: url('https://svgsilh.com/svg/159680.svg');
    height: 60px;
    width: 60px;
    opacity: 20%;
}

.truncated_title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.truncated_title:hover {
    overflow: visible;
    white-space: normal;
}

.MuiSlider-valueLabelOpen {
    position: relative;
}

.MuiSlider-valueLabelCircle {
    background-color: 'white';
}